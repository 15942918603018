@import url('https://fonts.googleapis.com/css2?family=Martel&family=Roboto+Flex:opsz@8..144&family=Roboto:wght@300&display=swap');


    body {
    margin: 0 auto;
    padding: 0;
}

* {
    box-sizing: border-box;
}

.containerDivAboutMe {
    margin: 0 auto;
    width: 100vw;
    padding: 1em 0 1em 0;
    font-size: auto;
    border-radius: 4px; 
}

.innerDivAboutMe {
    height: auto;
    width: 20em;
    margin: 0 auto;
    padding: .7em;
    letter-spacing: .07rem;
    
}

.aboutMeImg {
    float: left;
    width: 4em;
    height: 4em;
    background-size: cover;
    background-repeat: no-repeat;
    margin: .4em;
    border-radius: 50%;
}

@media (min-width: 600px) {

    .containerDivAboutMe {
        max-width: 20vw;
        font-size: auto;
        
    }

    .innerDivAboutMe {
        margin: 0 auto;
        width: auto;
    }
    
}

@media (min-width: 1025px) {
    .containerDivAboutMe {
        max-width: 30vw;
        font-size: 1.5rem;
    }
}