@import url('https://fonts.googleapis.com/css2?family=Martel&family=Roboto+Flex:opsz@8..144&family=Roboto:wght@300&display=swap');


body {
    margin: 0 auto;
    padding: 0;
}

* {
    box-sizing: border-box;
}

.navContainerOuter {
    position: absolute;
    pointer-events: none;
    font-family: var(--ff-main1); 
} 

.navContainer {   
    border-radius: 4px;
    z-index: 2;
    pointer-events: none;
    border: 2px solid transparent;  
}

.hamburger {
    position: absolute; 
    height: 2.2em;
    width: 2.2em;
    border: none;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    display: block;
    visibility: visible;
    cursor: pointer;
    pointer-events: auto;
    top: 1.5em; 
    z-index: 3;
}

.hambarContainer {
    padding-top: .3em;
    
} 

#hamburger-innerdiv {
    position: relative;
    background: var(--dark-blue);
    margin: .3em auto .3em auto;
    height: .2em;
    width: 1.5em;
    border-radius: 4px; 
    border: none;
    transition: 0.3s;
}

.change.hambar1 {
    transform: rotate(45deg);
    top: .5em;
}

.change.hambar2 {
    opacity: 0;
}

.change.hambar3 {
    transform: rotate(-45deg);
    bottom: .5em;
}

.navBar, nav {
    display: block; 
    visibility: visible;
    position: relative;  
    background: var(--backgroundColor);
    box-shadow: 0px 4px 8px var(--dark-teal-drop-1), 0px 8px 16px var(--dark-teal-drop-2);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    margin: 5em auto 0 auto; 
    padding-bottom: 4.1em;
    font-size: .8rem; 
    z-index: 2;
}

.hideNav {
    display: none;
    visibility: hidden;
}

.navUl {
    list-style-type: none;
    pointer-events: none; 
    text-align: right;
}

.navLi {
    border-radius: 7px; 
    color: var(--grey);
    padding: .5em;
    text-transform: uppercase;
    pointer-events: auto;
    cursor: pointer;
    font-weight: 400;
    letter-spacing: .05rem;
}

.navLiAnchor {
    flex-wrap: wrap;
    text-decoration: none;
    color: var(--brown);
    text-align: right;
}



@media (min-width: 600px) {

    .navContainerOuter {
        left: 25%;
        width: 50%;
    }

    .navBar, nav, .hideNav {
        display: block;
        visibility: visible;
        border: none;
        box-shadow: none;
        background: transparent;
         
    }

    .navUl {
        display: flex;       
        padding: .2em .3em 0 0;
        justify-content: space-around; 
    }

    .navLi {
        border: none;
        background: none;
        border-radius: 7px;
        padding: 0em;
        text-align: center;
        letter-spacing: .1rem;
    }

    .navLi p {
        transition: 0.2s;
        pointer-events: auto;
        transition: border .5s ease-in-out;
    }

    .navLiAnchor {
        flex-wrap: nowrap;
        font-size: .9rem;
        pointer-events: auto;
    }

    .hamburger {
        display: none;
        visibility: hidden;
    } 

}
@media (min-width: 1025px) {

    .navContainerOuter {
        border: 2px solid transparent; 
    } 

    .navBar, nav, .hideNav {
        border: none;
    }

    .bookBtn:hover,
    .bookBtn:focus {
        transform: scale(1.2);
        cursor: pointer;
    }  
}