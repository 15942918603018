@import url('https://fonts.googleapis.com/css2?family=Martel&family=Roboto+Flex:opsz@8..144&family=Roboto:wght@300&display=swap');

body {
    margin: 0 auto;
    padding: 0;
}

* {
    box-sizing: border-box;
}

.footerOuterContainer {
    margin: 0 auto;
}

.footerContainer {
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: screen;
    font-family: var(--ff-main);
}

.footerContainer p {
    color: var(--textdetailColor);
    background: var(--light-blue);
    text-align: center;
    padding: 1em;
    margin: 0 auto;
    letter-spacing: .07rem;
}

.socialMediaContainer {
    display: flex;
    width: 80vw;
    margin: 0 auto;
    padding: 1em;
    justify-content: space-between;
}

.socialMediaIcon {
    height: 1.5em;
    width: 1.5em;
    background-size: contain;
    justify-content: space-between;
    cursor: pointer;
}


@media (min-width: 600px) {
    .socialMediaContainer {
        margin: 0 auto;

    }

    .socialMediaIcon {
        margin: 2em auto;

    }


    .socialMediaIcon {
        z-index: 3;
        height: 2.5em;
        width: 2.5em;
    }
}
