
body {
    margin: 0 auto;
    padding: 0;
}

* {
    box-sizing: border-box;
}

.listenContainer {
    margin: 0 auto 2em auto;
    width: 100vw;
    margin-top: 2em;
    padding: .7em;
    background-color:rgba(71, 43, 1, 0.71);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    /* Full height */
    height: 100%; 
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
}

.listenInnerContainer {
    border: 1px solid var(--backgroundColor);
    border-radius: 7px; 
    text-align: center;
    height: auto;  
    width: 90vw;
    margin: 0 auto;
    color: var(--backgroundColor);
    padding: .7em 1.4em .7em .7em;
    letter-spacing: .07rem;
}

@media (min-width: 600px) {

    .listenInnerContainer {
        margin: 0 auto;
        width: 90vw;

    }
}
@media (min-width: 1025px) {

}